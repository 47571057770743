.bio {
  display: grid;
  grid-template-columns: $grid_columns;
  grid-column-gap: $grid_margin;
  grid-row-gap: $grid_margin;
  grid-auto-rows: min-content;

  > h1 {
    margin: 0;
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
  }

  // The bio image occupies the lefthand column
  > figure {
    grid-column: 1 / span 1;
    grid-row: 1 / span 3;
  }

  // The bio description occupies the two righthand columns
  > p {
    grid-column: 2 / span 2;
    grid-row: 2 / span 1;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  // The bio link occupies the two righthand columns
  > nav {
    grid-column: 2 / span 2;
    grid-row: 3 / span 1;
  }
}

@media (max-width: $mini_max_content_width) {
  .bio {
    // The heading is padded to make room for the bio image
    > h1 {
      margin: 0;
      grid-column: 1 / span 3;
      grid-row: 1 / span 1;
    }

    // The bio image is to the left of the heading
    > figure {
      margin: 0 auto;
      grid-column: 1 / span 3;
      grid-row: 2 / span 1;
      width: 70vw;
    }

    // The bio description occupies all three columns
    > p {
      grid-column: 1 / span 3;
      grid-row-start: auto;
    }

    // The bio link occupies all three columns
    > nav {
      grid-column: 1 / span 3;
      grid-row-start: auto;
    }
  }
}
