// Classes
// .heart
//   Centers a short message within a beating heart image
// .copyright
//   Intended for a copyright message

body > footer {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: $grid_columns;
  grid-column-gap: $grid_margin;
  grid-auto-rows: min-content;

  & > .heart {
    margin: 0;
    // A total vertical padding of 100% with a height of 0 creates a square
    padding: 27.5% 0 72.5%;
    grid-column: 1 / span 1;
    position: relative;
    font-size: 3.25vw;
    text-align: center;
    height: 0;

    // Beating heart image
    &::after {
      animation: heartbeat 2s ease-in-out infinite alternate;
      background-image: url('/img/sprite.6c323492.svg#heart');
      background-size: contain;
      display: block;
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  // Display at the bottom right of the grid
  & > .copyright {
    margin: 0;
    grid-column: 3 / span 1;
    align-self: end;
    font: 1rem $font_display;
    text-align: right;
    color: $color_accent;
  }
}

@media (max-width: $mini_max_content_width) {
  body > footer {
    display: block;

    // Center the heart horizontally
    & > .heart {
      margin: 0 auto;
      padding: 15% 0 0;
      font-size: 8vw;
      height: 40vw;
      width: 40vw;
    }

    // Display centered at the bottom of the page
    & > .copyright {
      margin-top: 2rem;
      text-align: center;
    }
  }
}
