.repos {
  display: grid;
  grid-template-columns: $grid_columns;
  grid-column-gap: $grid_margin;
  grid-auto-rows: min-content;
  position: relative;

  &::before,
  &::after,
  & > h1::before,
  & > h1::after,
  & > article:first-of-type::before,
  & > article:last-of-type::after {
    animation: float_vertical 8s ease-in-out infinite alternate;
    background-image: url('/img/sprite.6c323492.svg#star');
    background-repeat: no-repeat;
    background-size: cover;
    content: '';
    position: absolute;
    opacity: 0.5;
    pointer-events: none;
  }

  // Heading spans all three columns
  & > h1 {
    grid-column: 1 / span 3;
    grid-row: 1 / span 1;

    // Add the closest star to the lefthand side
    &::before {
      animation-duration: 8s;
      top: 14rem;
      left: -4rem;
      width: 3rem;
      height: 3rem;
    }

    // Add the closest star to the righthand side
    &::after {
      animation-duration: 6s;
      animation-delay: -7s;
      top: 13rem;
      right: -4rem;
      width: 3.5rem;
      height: 3.5rem;
      max-width: calc(#{(100vw - $content_width) / 2} - 4rem + 3.5rem);
    }
  }

  // Each repo spans one column
  & > article {
    grid-row: 2 / span 1;

    // Add the outermost star to the lefthand side
    &:first-of-type::before {
      animation-duration: 7s;
      animation-delay: -3s;
      top: 17rem;
      left: -11rem;
      width: 3rem;
      height: 3rem;
    }

    // Add a star to the righthand side's middle
    &:last-of-type::after {
      animation-duration: 8s;
      animation-delay: -10s;
      top: 14rem;
      right: -7rem;
      width: 2.5rem;
      height: 2.5rem;
    }

    & > h1,
    & > h2 {
      margin-top: 0;
    }
  }

  // Add a moon to the lefthand side's middle
  &::before {
    animation-duration: 9s;
    animation-delay: -14s;
    background-image: url('/img/sprite.6c323492.svg#moon');
    top: 15rem;
    left: -7.5rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  // Add the outermost star to the righthand side
  &::after {
    animation-duration: 9s;
    animation-delay: -20s;
    top: 16rem;
    right: -10rem;
    width: 3rem;
    height: 3rem;
  }
}

@media (max-width: $mini_max_content_width) {
  .repos {
    // Each repo spans all three columns
    & > article {
      grid-column: 1 / span 3;
      grid-row-start: auto;
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
