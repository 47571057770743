// Define display and body fonts
$font_display: 'Varela Round', sans-serif;
$font_body: 'Cormorant Garamond', adobe-garamond-pro, serif;
$font_size: 20px;

// Define a 3-column grid layout with margins
$grid_columns: repeat(3, 1fr);
$grid_margin: 3rem;

// Define the background, accent, and text color
$color_accent: #ff7aab;
$color_background: #fff;
$color_text: #222;

// Define the site dimensions
$content_width: 80vw;
$max_content_width: 800px;
$mini_max_content_width: 650px; // Mini site layout
