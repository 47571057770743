// Enlarges an element as if it were beating like a heart
@keyframes heartbeat {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

// Animation for vertically floating stars
@keyframes float_vertical {
  0% {
    transform: translateY(-50%) rotate(-20deg);
  }

  100% {
    transform: translateY(125%) rotate(10deg);
  }
}
