// Classes
// .button
//   Renders a large inline-block button, for use with a
// .understate
//   Renders text in the base text color, for use with span in an h1

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font: $font_size $font_body;
  // Prevent horizontal scrolling items (stars) are beyond the right edge of the viewport
  overflow-x: hidden;
}

body {
  background: $color_background url('/img/sprite.6c323492.svg#curly-heart') 0 3.5rem repeat-x;
  background-size: 3rem;
  margin: 12rem 0 5rem;
  padding: 0;
  font: $font_size $font_body;
  text-transform: lowercase;
  color: $color_text;

  // Set the content width for the page
  & > article,
  & > footer,
  & > nav {
    margin: 0 auto;
    width: $content_width;
    max-width: $max_content_width;
  }
}

h1, h2, h3 {
  font-family: $font_display;
  font-weight: normal;
  color: $color_accent;
  line-height: 1.25;
}

h1 {
  margin: 5rem 0;
  font-size: 3rem;
}

h2 {
  font: 1.5rem $font_body;
  color: $color_text;
}

a {
  transition: all 0.3s ease;
  color: $color_accent;

  // Buttons are more block-level than inline anchors
  &.button {
    border-bottom: 2px solid $color_accent;
    padding: 1ex 1em calc(1ex - 2px);
    display: inline-block;
    font-family: $font_display;
    text-decoration: none;
    line-height: 1;

    &:hover {
      background: $color_accent;
      color: $color_background;
    }
  }
}

[data-icon] {
  text-indent: -100vw;
  white-space: nowrap;
  overflow: hidden;
  position: relative;

  &::before {
    background: no-repeat 50% calc(50% + 1px)/80%;
    transition: filter 0.3s ease;
    content: '';
    filter: brightness(1);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:hover::before {
    filter: brightness(10);
  }
}

[data-icon='github']::before {
  background-image: url('/img/sprite.6c323492.svg#github');
}

[data-icon='twitter']::before {
  background-image: url('/img/sprite.6c323492.svg#twitter');
}

[data-icon='linkedin']::before {
  background-image: url('/img/sprite.6c323492.svg#linkedin');
}

figure {
  margin: 0;
}

img {
  -webkit-border-image: url('/img/sprite.6c323492.svg#wave') 40 40 40 40 / 20px stretch;

  // Workaround for WebKit browsers inheriting the border width of -webkit-border-image
  @supports (border-image: url('/img/sprite.6c323492.svg#wave')) {
    -webkit-border-image: url('/img/sprite.6c323492.svg#wave') 0 0 0 0 / 0px stretch;
    border-image: url('/img/sprite.6c323492.svg#wave') 40 fill / 20px / 20px stretch;
  }

  border-width: 1px;
  border-image: url('/img/sprite.6c323492.svg#wave') 40 fill / 20px / 20px stretch;
  max-width: 100%;
}

// Renders text in a heading with the regular text color
.understate {
  color: $color_text;
}
